import styles from 'components/CmsComponents/components/CmsBlogPosts/CmsBlogPosts.module.scss';
import BlogCard from 'components/BlogCard/BlogCard';
import Button from '@wienenergiegithub/ui-next/src/common/components/Button/Button';
import { Link } from 'gatsby';
import React from 'react';

const CmsBlogPosts = ({ items, link }) => (
  <>
    <div className={styles.container}>
      {items.map(item => (
        <BlogCard {...item} key={`${item.title}`} />
      ))}
    </div>
    {link?.url && (
      <div className={styles.buttonContainer}>
        <Button as={Link} to={link.url} theme="primary" variant="outline">
          {link.title}
        </Button>
      </div>
    )}
  </>
);

export default CmsBlogPosts;
