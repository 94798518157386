/* eslint-disable no-nested-ternary */
import CmsCard from 'components/CmsComponents/components/CmsCard/CmsCard';
import styles from 'components/CmsComponents/components/CmsCardList/CmsCardList.module.scss';
import CardListSlider from 'components/Slider/CardListSlider/CardListSlider';
import React from 'react';
import classNames from 'classnames';
import { Heading } from '@wienenergiegithub/ui-next';

const CmsCardListSlider = ({ name, cards, type, sliderSettings }) => {
  return (
    <div className={classNames(styles.cards, styles.slider)}>
      <CardListSlider name={name} settings={sliderSettings}>
        {cards.map(card => (
          <CmsCard inSlider type={type} {...card} key={card.action.url} />
        ))}
      </CardListSlider>
    </div>
  );
};
CmsCardListSlider.defaultProps = {
  sliderSettings: {
    slidesToShow: 3.3,
  },
};

const CmsCardListStatic = ({ cards, type, className, titleTag }) => (
  <div className={className}>
    {cards.map(card => (
      <CmsCard
        {...card}
        titleTag={titleTag}
        type={type}
        key={`${card.action?.url}-${card.title}-${card.description}`}
        className={styles.cardListItem}
      />
    ))}
  </div>
);

const CmsCardList = ({ name, title, titleTag, isSlider, type, cards }) => {
  const renderSlider = () => {
    switch (type) {
      case 'contrast':
        return (
          <CmsCardListSlider
            name={name}
            type={type}
            titleTag={titleTag}
            cards={cards}
          />
        );
      case 'iconSlidingCard':
        return (
          <CmsCardListSlider
            name={name}
            type={type}
            titleTag={titleTag}
            cards={cards}
          />
        );
      default:
        return null;
    }
  };

  const renderList = () => {
    return (
      <CmsCardListStatic
        className={classNames(styles.gridList, styles[type])}
        type={type}
        titleTag={titleTag}
        cards={cards}
      />
    );
  };

  return (
    <div>
      {title && <Heading size="h2">{title}</Heading>}
      {isSlider ? renderSlider(type) : renderList(type)}
    </div>
  );
};
export default CmsCardList;
