import React from 'react';
import styles from './ImageCard.module.scss';
import {
  Card,
  Content,
  Heading,
  Image
} from '@wienenergiegithub/ui-next';

const ImageCard = ({ title, description, image }) => {
  return (
    <Card>
      <div className={styles.cardContainer}>
        <Image
          alt={title}
          src={image}
          className={styles.cardImage}
        />
        <div className={styles.contentContainer}> 
          <Heading size="h5">{title}</Heading>
          <Content style={{ marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </Card>
  );
};

export { ImageCard as default };
