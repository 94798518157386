import PropTypes from 'prop-types';
import styles from '@wienenergiegithub/ui-next/src/common/components/BlogCard/BlogCard.module.scss';
import Card from '@wienenergiegithub/ui-next/src/common/components/Card/Card';
import Content from '@wienenergiegithub/ui-next/src/common/components/Content/Content';
import React from 'react';
import Heading from '@wienenergiegithub/ui-next/src/common/components/Heading/Heading';
import { Link } from 'gatsby';
import Image from '@wienenergiegithub/ui-next/src/common/components/Image/Image';
import classNames from 'classnames';

const BlogCard = ({ title, image, excerpt, link, date, className }) => (
  <Card
    as={Link}
    to={link}
    className={classNames(className, styles.card)}
    variant="elevated"
  >
    {image && <Image alt={title} className={styles.image} src={image.src} />}
    <Heading as="h3" size="h6" className={styles.title}>
      {title}
    </Heading>
    <Content withParser className={styles.content}>
      {excerpt}
    </Content>
    <div className={styles.date}>{date}</div>
  </Card>
);

BlogCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
  }).isRequired,
  excerpt: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  className: PropTypes.string,
};

BlogCard.defaultProps = {
  className: null,
};

export default BlogCard;
