import "fontsource-fira-sans/200.css"
import "fontsource-fira-sans/400.css"
import "fontsource-fira-sans/600.css"
import "fontsource-fira-sans/700.css"

import registerFontAwesomeIcons from './registerIcons';
import PropTypes from 'prop-types';
import './Wrapper.scss';

registerFontAwesomeIcons();

const Wrapper = ({ children }) => {
  return children;
};
Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Wrapper;
