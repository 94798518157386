import styles from 'components/CmsComponents/components/CmsStage/CmsStage.module.scss';
import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import {
  IconCard,
  Image
 } from '@wienenergiegithub/ui-next/';

const CmsStage = ({
  icon,
  heading,
  content,
  image,
  action,
  isReverse,
  variant,
  className,
}) => {
  const isProminent = variant === 'teaser' || variant === 'contentCard';
  const headingSizes = {
    teaser: 'h2',
    contentCard: 'h4',
    default: 'h5',
  };

  return (
    <div
      className={classNames(styles.container, className, styles[variant], {
        [styles.reverse]: isReverse,
        [styles.hasBubble]: !isProminent && !!action?.url,
      })}
    >
      {image && (
        <Image
          className={styles.image}
          title={image.title}
          alt={image.title}
          src={image.src}
          sizes="(min-width: 960px) 540px, 100vw"
          srcSet={image.srcSet}
        />
      )}
      <div className={styles.cardWrapper}>
        <IconCard
          as={action?.url && Link}
          to={action?.url}
          target={action?.target || '_self'}
          headingSize={headingSizes[variant]}
          button={isProminent ? action : null}
          glyph={!icon?.src ? icon : null}
          icon={icon?.src}
          title={heading}
          description={content}
          hasAction={!isProminent && !!action?.url}
        />
      </div>
    </div>
  );
};

CmsStage.defaultProps = {
  variant: 'default',
};

export default CmsStage;
