import styles from 'components/CmsComponents/components/CmsVimeo/CmsVimeo.module.scss';
import PropTypes from 'prop-types';
import React from 'react';

const CmsVimeo = ({
  title: videoTitle,
  is_album: isAlbum,
  vimeo_id: vimeoId,
  video_size: videoSize,
  loop: isLooped,
  is_autoplay: isAutoplay,
  controls: isControlHidden,
  dnt: cookies,
  is_muted: isMuted,
}) => {
  const baseUrl = isAlbum
    ? `https://vimeo.com/album/${vimeoId}/embed`
    : `https://player.vimeo.com/video/${vimeoId}`;

  // eslint-disable-next-line compat/compat
  const url = new URL(
    `${baseUrl}?autoplay=${isAutoplay}&loop=${isLooped}&muted=${isMuted}&cookies=${cookies}&controls=${isControlHidden}`,
  );

  // size structure from WP -> 640:360
  const [width, height] = videoSize.split(':');

  return (
    <div className={styles.container}>
      <iframe
        src={url.href}
        width={width}
        height={height}
        title={videoTitle}
        allowFullScreen
        frameBorder="0"
        className={styles.video}
        allow="autoplay"
      />
    </div>
  );
};

CmsVimeo.propTypes = {
  title: PropTypes.string.isRequired,
  is_album: PropTypes.bool,
  vimeo_id: PropTypes.string.isRequired,
  video_size: PropTypes.string.isRequired,
  loop: PropTypes.bool,
  is_autoplay: PropTypes.bool,
  controls: PropTypes.bool,
  dnt: PropTypes.bool,
  is_muted: PropTypes.bool,
};
CmsVimeo.defaultProps = {
  is_album: false,
  loop: false,
  is_autoplay: true,
  controls: true,
  dnt: true,
  is_muted: true,
};
export default CmsVimeo;
