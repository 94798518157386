import styles from 'components/CmsComponents/components/CmsTextImage/CmsTextImage.module.scss';
import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { 
  Heading, 
  Video, 
  Content, 
  Button,
  List,
  FontAwesome as FontAwesomeProIcon,
  Image,
} from '@wienenergiegithub/ui-next';


const CmsTextImage = ({
  heading,
  content,
  list,
  second_content: secondContent,
  image,
  video,
  href,
  secondary_href: secondaryHref,
  type,
}) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.contentOnly]: !image && !video,
      })}
    >
      <div className={styles.content}>
        {heading && (
          <Heading size="h1" className={styles.heading} as={type}>
            {heading}
          </Heading>
        )}

        {content && (
          <Content size="md" as="div">
            {content.split('\n').map((i, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={index}>{i}</p>
            ))}
          </Content>
        )}
        {list?.length && (
          <List
            className={styles.list}
            type="checkmark"
            icon={
              <FontAwesomeProIcon
                type="regular"
                icon="check"
                role="presentation"
              />
            }
          >
            {list.map((item, index) => (
              <ListItem
                // eslint-disable-next-line react/no-array-index-key
                key={`listItem-${index}`}
              >
                {item}
              </ListItem>
            ))}
          </List>
        )}
        {secondContent && (
          <Content size="md" as="div">
            {secondContent.split('\n').map((i, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={index}>{i}</p>
            ))}
          </Content>
        )}

        {href && (
          <Button
            as={Link}
            target={href.target}
            to={href.url}
            className={styles.button}
            isElevated
          >
            {href.title}
          </Button>
        )}
        {secondaryHref && (
          <Button
            theme="secondary"
            className={styles.button}
            as={Link}
            target={secondaryHref.target}
            to={secondaryHref.url}
            isElevated
          >
            {secondaryHref.title}
          </Button>
        )}
      </div>
      {image && (
        <figure className={styles.imageContainer}>
          <Image
            className={styles.image}
            srcSet={image.srcSet}
            sizes="(min-width: 960px) 733px, 100vw"
            src={image.src}
            alt={image.title}
          />
        </figure>
      )}
      {video && video.video && (
        <figure className={styles.videoContainer}>
          <Video
            muted={video.is_muted}
            controls={video.show_controls}
            poster={video.poster}
            autoPlay={video.autoplay_enabled}
            loop={video.loop_enabled}
            title={video.title}
            preload="none"
            className={styles.video}
            video={video.video}
            track={video.track}
          />
        </figure>
      )}
    </div>
  );
};

export default CmsTextImage;
