import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Accordion, {
  AccordionGroup,
} from '@wienenergiegithub/ui-next/src/common/components/Accordion/Accordion';
import Content from '@wienenergiegithub/ui-next/src/common/components/Content/Content';

const CmsAccordion = ({
  items,
  multiple_open: multipleOpen,
  withoutParser,
  ...props
}) => {
  const [defaultOpenIndex, setDefaultOpenIndex] = useState(-1);

  useEffect(() => {
    // eslint-disable-next-line compat/compat
    const hash = window.location.hash.substr(1);
    const index = items.findIndex(item => item?.anchor?.trim() === hash);
    setDefaultOpenIndex(index);
  }, []);

  return (
    <AccordionGroup
      allowMultiple={multipleOpen}
      defaultOpenIndex={defaultOpenIndex}
      {...props}
    >
      {items.map(({ title, subtitle, anchor, content }) => (
        <Accordion
          key={title}
          title={title}
          anchor={anchor}
          subtitle={subtitle}
        >
          <Content withParser={!withoutParser}>{content}</Content>
        </Accordion>
      ))}
    </AccordionGroup>
  );
};

CmsAccordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      content: PropTypes.string,
    }),
  ).isRequired,
  multipleOpen: PropTypes.bool,
  withoutParser: PropTypes.bool,
};
CmsAccordion.defaultProps = {
  withoutParser: false,
  multipleOpen: false,
};

export default CmsAccordion;
