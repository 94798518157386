import styles from 'components/Ie11Modal/Ie11Modal.module.scss';
import { useSessionStorage } from 'hooks/storageHook.js';
import logo from 'components/images/logo-white-outline.include.svg';
import React, { useEffect } from 'react';

import {
    Button,
    Content,
    Heading,
    Modal
} from '@wienenergiegithub/ui-next';

import { useDialogState } from 'reakit/Dialog';

const Header = () => {
  return <img alt="Wien Energie Logo" src={logo} className={styles.logo} />;
};

const Ie11Modal = () => {
  const dialog = useDialogState({
    hideOnEsc: false,
  });
  const [seen, setSeen] = useSessionStorage(`ie11Notice`, false);

  useEffect(() => {
    const isIe11 = !!window.MSInputMethodContext && !!document.documentMode;
    if (isIe11 && !seen) {
      dialog.show();
    }
  }, []);

  const handleClick = () => {
    setSeen(true);
    dialog.hide();
  };

  return (
    <Modal
      theme="secondary"
      header={<Header />}
      dialog={dialog}
      actions={<span />}
      hideOnClickOutside={false}
    >
      <Heading size="h4">
        Die Webseiten von Wien Energie sind nicht für die Darstellung mit
        Internet Explorer 11 optimiert.
      </Heading>
      <Content size="regular">
        Für eine korrekte Darstellung unserer Seiten (und vieler anderer
        Webseiten weltweit) empfehlen wir den Umstieg auf aktuelle Browser wie
        Google Chrome, Mozilla Firefox oder Microsoft Edge.
      </Content>
      <Button onClick={handleClick} isElevated>
        Diesen Hinweis nicht mehr anzeigen.
      </Button>
    </Modal>
  );
};

export default Ie11Modal;