import useTracking from 'hooks/tracking';
import useCombinedRefs from 'hooks/combinedRefs';
import React from 'react';
import SlickSlider from 'react-slick';

const Slider = React.forwardRef(
  ({ name = 'slider', children, ...other }, ref) => {
    const track = useTracking();
    const innerRef = React.useRef(null);
    const combinedRef = useCombinedRefs(ref, innerRef);

    const handleAfterChange = index => {
      track({
        event: 'horizontal-scroll',
        element: index,
        name,
        visibleBefore: [
          ...combinedRef.current.innerSlider.list.querySelectorAll(
            '.slick-active',
          ),
        ].map(item => item.getAttribute('data-index')),
      });
    };
    return (
      <SlickSlider ref={combinedRef} afterChange={handleAfterChange} {...other}>
        {children}
      </SlickSlider>
    );
  },
);

export { Slider as default };
