import Wrapper from 'components/Wrapper/Wrapper';
import Layout from 'components/Layout/Layout';
import Navigation from 'components/Navigation/Navigation';
import Ie11Modal from 'components/Ie11Modal/Ie11Modal';
import BlockMatcher from 'components/BlockMatcher/BlockMatcher';
import PartnerCardList from 'components/PartnerCardList/PartnerCardList';
import CmsSpacer from 'components/CmsComponents/components/CmsSpacer/CmsSpacer';
import Footer from 'components/Footer/Footer';
import SEO from 'components/SEO/SEO';

import React from 'react';
import { Container } from '@wienenergiegithub/ui-next';

const pageTemplate = props => {
  const { pageContext } = props;
  const { pageContent } = pageContext;

  // Differenciating between blocks used on /projektpartner
  const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';
  const isPartnerOverview = currentPath !== '/projektpartner' ? true : false;
  let PartnerCardContainer = <></>;
  if (isPartnerOverview === true) {
    PartnerCardContainer = (
      <Container size="full" style={{ backgroundColor: '#f7f8f8' }}>
        <Container>
          <CmsSpacer height="100" />
          <PartnerCardList type="slider" />
          <CmsSpacer height="100" />
        </Container>
      </Container>
    );
  }

  return (
    <Wrapper>
      <Layout>
        <Ie11Modal />
        <Navigation path={currentPath} />
        <SEO title={pageContext.pageTitle} description={pageContext.pageDescription} />

        <main>
          {pageContent.map((block, index) => (
            <Container size={block.blocksize} key={`content_item_${index}`}>
              <BlockMatcher block={block} />
            </Container>
          ))}
        </main>

        {PartnerCardContainer}

        <Footer />
      </Layout>
    </Wrapper>
  );
};
export default pageTemplate;
