import styles from './ContactOverview.module.scss';
import CmsCallToAction from 'components/CmsComponents/components/CmsCallToAction/CmsCallToAction';
import { Content, Figure, Heading } from '@wienenergiegithub/ui-next';
import imageAstridSalmhofer from '../../../content/assets/images/astrid-salmhofer.jpg';
import imageLisaGrohs from '../../../content/assets/images/lisa-grohs.jpg';

import React from 'react';

const ContactOverview = () => {
  const salmhofer = [
    {
      icon: 'phone',
      link: {
        url: 'tel:01400438200',
        title: 'Telefon: (0)1 4004-38200',
        target: 'blank',
      },
      theme: 'contrast',
    },
    {
      icon: 'envelope-open-text',
      link: {
        url: 'mailto:astrid.salmhofer@wienenergie.at',
        title: 'astrid.salmhofer@wienenergie.at',
      },
      theme: 'default',
    },
  ];

  const grohs = [
    {
      icon: 'phone',
      link: {
        url: 'tel:01400438073',
        title: 'Telefon: (0)1 4004-38073',
        target: 'blank',
      },
      theme: 'contrast',
    },
    {
      icon: 'envelope-open-text',
      link: {
        url: 'mailto:lisa.grohs@wienenergie.at',
        title: 'lisa.grohs@wienenergie.at',
      },
      theme: 'default',
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.containerElement}>
        <Figure src={imageAstridSalmhofer} alt="Bild von Astrid Salmhofer" />
        <div style={{marginBottom:"1.25rem"}} />
        <Heading size="h6">Astrid Salmhofer</Heading>
        <Content>Leitung Unternehmenskommunikation</Content>
        <CmsCallToAction items={salmhofer} />
      </div>
      <div className={styles.containerElement}>
        <Figure src={imageLisaGrohs} alt="Bild von Lisa Grohs" />
        <div style={{marginBottom:"1.25rem"}} />
        <Heading size="h6">Mag. Lisa Grohs</Heading>
        <Content>Pressesprecherin</Content>
        <CmsCallToAction items={grohs} />
      </div>
    </div>
  );
};

export default ContactOverview;
