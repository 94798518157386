import CmsBlogPosts from 'components/CmsComponents/components/CmsBlogPosts/CmsBlogPosts';
import React from 'react';
import { useStaticQuery, StaticQuery, graphql } from 'gatsby';

const BlogPostsBlock = ({ count }) => {
  // Defining query
  const data = useStaticQuery(graphql`
    query HomePageQuery {
      allPostsYaml {
        edges {
          node {
            date
            excerpt
            path
            title
          }
        }
      }
    }
  `);

  // Defining output variable
  let items = [];

  // Querying data
  data.allPostsYaml.edges.forEach(element => {
    const postData = {
      title: element.node.title,
      excerpt: element.node.excerpt,
      date: element.node.date,
      link: `/aktuelles${element.node.path}`,
    };
    items.push(postData);
  });

  // Sorting posts by date
  items.sort(function(a, b) {
    return b.date.localeCompare(a.date);
  });

  // Only showing number of posts as defined in block
  items = items.slice(0, count);

  // Converting date to german string format
  items.forEach(element => {
    const itemDate = new Date(element.date);
    element.date = itemDate.toLocaleDateString('de-DE');
  });

  return <CmsBlogPosts items={items} />;
};

export default BlogPostsBlock;
