import styles from 'components/CmsComponents/components/CmsCard/CmsCard.module.scss';
import CmsStage from 'components/CmsComponents/components/CmsStage/CmsStage';
import React from 'react';
import { Link } from 'gatsby';
import {
  Button,
  FontAwesome as FontAwesomeProIcon,
  Heading,
  IconCard,
  Image
} from '@wienenergiegithub/ui-next/';

import Card, {
  ElevatedAction,
} from '@wienenergiegithub/ui-next/src/common/components/Card/Card';
import classNames from 'classnames';

const TextSlidingCard = ({ title, type, action }) => {
  const handleActionClick = e => {
    e.preventDefault();
    window.open(action.url, action.target || '_self');
  };

  return (
    <Link
      to={action?.url}
      target={action?.target || '_self'}
      className={styles.cardLink}
      title={title}
    >
      <Card
        hasAction={!!action?.url}
        theme={type}
        className={styles.textSlidingCard}
      >
        <Heading size="h6" className={styles.textSlidingCardTitle}>
          {title}
        </Heading>
        {action && (
          <ElevatedAction>
            <Button
              shape="bubble"
              aria-label={`${title} Besuchen`}
              onClick={handleActionClick}
              tabIndex="-1"
            >
              <FontAwesomeProIcon icon="chevron-right" role="presentation" />
            </Button>
          </ElevatedAction>
        )}
      </Card>
    </Link>
  );
};

const IconSlidingCard = ({ title, icon, action }) => {
  const handleActionClick = e => {
    e.preventDefault();
    window.open(action.url, action.target || '_self');
  };

  return (
    <Link
      to={action?.url}
      target={action?.target}
      className={styles.cardLink}
      title={title}
    >
      <Card hasAction={!!action?.url} className={styles.iconSlidingCard}>
        <div className={styles.iconSlidingCardIcon}>
          <Image
            alt={icon?.title || title}
            srcSet={icon?.srcSet ? icon?.srcSet : undefined}
            src={icon?.src}
            className={styles.iconSlidingCardImage}
          />
        </div>
        <Heading size="h6" className={styles.iconSlidingCardTitle}>
          {title}
        </Heading>

        {action && (
          <ElevatedAction>
            <Button
              shape="bubble"
              aria-label={`${title} Besuchen`}
              onClick={handleActionClick}
              tabIndex="-1"
            >
              <FontAwesomeProIcon icon="chevron-right" role="presentation" />
            </Button>
          </ElevatedAction>
        )}
      </Card>
    </Link>
  );
};

const CmsCard = ({
  type,
  inSlider,
  limitText,
  titleTag,
  limitHeading,
  isCompact,
  className,
  ...card
}) => {
  switch (type) {
    case 'contrast':
      return <TextSlidingCard titleTag={titleTag} {...card} type={type} />;
    case 'helpCard':
      return (
        <IconCard
          {...card}
          className={className}
          isCompact={isCompact}
          as={card.action?.url && Link}
          to={card.action?.url}
          target={card.action?.target || '_self'}
          icon={null}
          titleTag={titleTag}
          glyph={card.icon}
          hasAction={!!card.action?.url}
          variant="iconTop"
        />
      );
    case 'infoCard':
      return (
        <IconCard
          {...card}
          className={className}
          titleTag={titleTag}
          isCompact={isCompact}
          icon={null}
          glyph={card.icon}
          hasAction={false}
          variant="iconTop"
          contentSize="md"
          theme="highlight"
        />
      );
    case 'contentCard':
      return (
        <CmsStage
          {...card}
          className={classNames(styles.card, className)}
          variant="contentCard"
          heading={card.title}
          action={card.action || null}
          content={card.description}
        />
      );
    case 'imageCard':
      return (
        <CmsStage
          {...card}
          className={className}
          icon={card.icon || null}
          heading={card.title}
          content={card.description}
        />
      );
    case 'iconSlidingCard':
      return <IconSlidingCard {...card} inSlider={inSlider} />;
    default:
      return null;
  }
};
export default CmsCard;
