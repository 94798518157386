import styles from './DownloadCards.module.scss';
import folderFile from '../../../content/assets/downloads/geotief-folder.pdf';
import exhibitionFile from '../../../content/assets/downloads/wanderausstellung-geotief.pdf';

import CmsCardList from 'components/CmsComponents/components/CmsCardList/CmsCardList';
import {
  Button,
  Container,
  Content,
  FontAwesome as FontAwesomeProIcon,
  Heading,
} from '@wienenergiegithub/ui-next';
import Card, {
  ElevatedAction,
} from '@wienenergiegithub/ui-next/src/common/components/Card/Card';
import React from 'react';

const DownloadCards = () => {
  return (
    <Container className={styles.container}>
      <Card variant="elevated" hasAction>
        <Heading size="h5">GeoTief Informationsfolder</Heading>
        <Content style={{marginBottom: 0}}>
          Wir sind auf der Suche nach mehr umweltfreundlicher Wärme für Wien. In unserem Folder erfahren Sie mehr über das Projekt GeoTief Wien.
        </Content>
        <ElevatedAction>
          <a href={folderFile} download>
            <Button aria-label="Downloaden" shape="bubble">
              <FontAwesomeProIcon icon="file-download" />
            </Button>
          </a>
        </ElevatedAction>
      </Card>
      <Card variant="elevated" hasAction>
        <Heading size="h5">Wanderausstellung</Heading>
        <Content style={{marginBottom: 0}}>
          Alle Informationen über die wissenschaftlichen Seismik-Messungen im Osten Wiens.
        </Content>
        <ElevatedAction>
          <a href={exhibitionFile} download>
            <Button aria-label="Downloaden" shape="bubble">
              <FontAwesomeProIcon icon="file-download" />
            </Button>
          </a>
        </ElevatedAction>
      </Card>
    </Container>
  );
};

export default DownloadCards;
