import BlogPostsBlock from 'components/BlogPostsBlock/BlogPostsBlock';
import CmsAccordion from 'components/CmsComponents/components/CmsAccordion/CmsAccordion';
import CmsCardList from 'components/CmsComponents/components/CmsCardList/CmsCardList';
import CmsList from 'components/CmsComponents/components/CmsList/CmsList';
import CmsMainTeaser from 'components/CmsComponents/components/CmsMainTeaser/CmsMainTeaser';
import CmsSpacer from 'components/CmsComponents/components/CmsSpacer/CmsSpacer';
import CmsTextImage from 'components/CmsComponents/components/CmsTextImage/CmsTextImage';
import CmsVimeo from 'components/CmsComponents/components/CmsVimeo/CmsVimeo';
import ContactOverview from 'components/ContactOverview/ContactOverview';
import DownloadCards from 'components/DownloadCards/DownloadCards';
import IFrameWrapper from 'components/IFrameWrapper/IFrameWrapper';
import ImageCard from 'components/ImageCard/ImageCard';
import InfoCards from 'components/InfoCards/InfoCards';
import PartnerCardList from 'components/PartnerCardList/PartnerCardList';

import React from 'react';
import { Link } from 'gatsby';

import {
  Button,
  Card,
  Content,
  Figure,
  Heading,
  IconCard
} from '@wienenergiegithub/ui-next';

const BlockMatcher = data => {
  let output;

  switch (data.block.blocktype) {
    case 'Button':
      output = <Button as={Link} to={data.block.content.link.url} target={data.block.content.link.target} theme={data.block.content.type} isElevated>{data.block.content.link.title}</Button>;
      break;
    case 'ContentCard':
      output = <Card variant="elevated"><Content style={{marginBottom: 0}}><b>{data.block.content.text}</b></Content></Card>;
      break;
    case 'AccordionGroup':
      output = <CmsAccordion items={data.block.items} />;
      break;
    case 'ContactOverview':
      output = <ContactOverview />;
      break;
    case 'Content':
      output = <Content dangerouslySetInnerHTML={{ __html: data.block.content.text }} />;
      break;
    case 'DownloadCards':
      output = <DownloadCards />;
      break;
    case 'BlogPostsBlock':
      output = <BlogPostsBlock count={data.block.count} />;
      break;
    case 'PartnerCardList':
      output = <PartnerCardList type={data.block.content.type} />;
      break;
    case 'CmsCardList':
      const cardListItems = [];
      data.block.items.forEach(element => {
        const cardListItemData = {
          title: element.title,
          description: element.content,
          action: {
            title: element.action.title,
            url: element.action.url,
            target: element.action.target,
          },
          image: {
            src: element.image.src.publicURL,
            title: element.image.title,
          }
        }
        cardListItems.push(cardListItemData);
      });
      output =   (
        <CmsCardList
          type="contentCard"
          cards={cardListItems}
        />
      );
      break;
    case 'CmsList':
      const items = [];
      data.block.items.forEach(element => {
        items.push(element.content);
      });
      output = <CmsList items={items} type={data.block.content.type} noSpacing />;
      break;
    case 'CmsMainTeaser':
      const item = [{
        heading: data.block.content.heading,
        subheading: data.block.content.text,
        href: {
          title: data.block.content.link.title,
          url: data.block.content.link.url,
          target: data.block.content.link.target,
        },
        image: {
          src: data.block.content.image.src.publicURL,
          title: data.block.content.image.title,
        },
      }];
      output = <CmsMainTeaser items={item} background={data.block.content.type} />;
      break;
    case 'CmsSpacer':
      output = <CmsSpacer height={data.block.content.height} />;
      break;
    case 'CmsTextImage':
      const blockImage = {
        src: data.block.content.image.src.publicURL,
        title: data.block.content.image.title,
      };
      output = (
        <CmsTextImage
          type={data.block.content.type}
          heading={data.block.content.heading}
          image={blockImage}
          href={data.block.content.link}
          content={data.block.content.text}
        />
      );
      break;
    case 'CmsVimeo':
      output = (
        <CmsVimeo
          title="fruit"
          is_album={false}
          vimeo_id="208138633"
          video_size="741:416"
          loop={false}
          is_autoplay={false}
          dnt={true}
          is_muted={true}
          controls={true}
        />
      );
      break;
    case 'Figure':
      output = <Figure className="large" src={data.block.content.image.src.publicURL} alt={data.block.content.image.title} caption={data.block.content.image.caption} />;
      break;
    case 'Heading':
      output = (
        <Heading size={data.block.content.type}>
          {data.block.content.text}
        </Heading>
      );
      break;
    case 'IFrameWrapper':
      output = <IFrameWrapper url={data.block.content.text} />;
      break;
    case 'IconCard':
      output = (
        <IconCard
          glyph={data.block.content.glyph}
          title={data.block.content.heading}
          description={data.block.content.text}
        />
      );
      break;
    case 'ImageCard':
      output = (
        <ImageCard
          title={data.block.content.heading}
          description={data.block.content.text}
          image={data.block.content.image.src.publicURL}
        />
      );
      break;
    case 'InfoCards':
      output = <InfoCards />;
      break;
    default:
      output = <p>NO MATCHING COMPONENT FOUND!</p>;
  }

  return <>{output}</>;
};

export default BlockMatcher;
