import {
  faCheck,
  faChevronDown,
  faChevronRight,
  faCircle,
  faFileDownload,
  faEnvelope,
  faEnvelopeOpenText,
  faHandsHeart,
  faInfo,
  faPhone,
  faChevronLeft,
  faQuestion,
  faShoePrints,
} from '@fortawesome/pro-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

const icons = [
  faCheck,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faCircle,
  faFileDownload,
  faEnvelope,
  faEnvelopeOpenText,
  faHandsHeart,
  faInfo,
  faPhone,
  faQuestion,
  faShoePrints,
];

export default function registerFontAwesomeIcons() {
  library.add(...icons);
}
