import styles from './Navigation.module.scss';
import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
import React, { useState, useCallback } from 'react';
import { Button } from '@wienenergiegithub/ui-next';
import { Link } from 'gatsby';
import { HamburgerSpin } from 'react-animated-burgers';
import classNames from 'classnames';
import logo from '../../assets/images/geotief-logo.png';

const Navigation = path => {
  // Adding mobile menu active logic
  const [isActive, setIsActive] = useState(false);
  const toggleButton = useCallback(
    () => setIsActive(prevState => !prevState),
    [],
  );
  const openClassName = isActive ? styles.open : '';

  // Defining navigation elements
  const navigationElements = [{
    link: "/ueber",
    title: "Über das Projekt"
  }, {
    link: "/projektpartner",
    title: "Projektpartner"
  }, {
    link: "/3d-modell",
    title: "3D-Modell"
  }, {
    link: "/aktuelles",
    title: "Aktuelles"
  }, {
    link: "/kontakt",
    title: "Kontakt"
  }];

  // Defining output of navigation elements
  let elementsOutput = [];
  navigationElements.forEach(element => {
    const activeClassName = (path.path==element.link || path.path==element.link+'/') ? styles.active : '';
    elementsOutput.push(
      <Button variant="link" to={element.link} as={Link} className={classNames(styles.menuElement, activeClassName)}>
        {element.title}
      </Button>
    );
  });

  return (
    <header>
      <Container>
        <div className={styles.container}>
          <div>
            <Link to="/">
              <img className={styles.logo} src={logo} alt="GeoTief Wien Logo" />
            </Link>
          </div>
          <div className={styles.burgerButton}>
            <HamburgerSpin buttonWidth={30} buttonStyle={{ alignItems: 'center', display: 'flex' }} {...{ isActive, toggleButton }} />
          </div>
          <div className={classNames(styles.menuContainer, openClassName)}>
            {elementsOutput}
          </div>
        </div>
      </Container>
    </header>
  );
};

export { Navigation as default };
