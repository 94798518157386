import styles from 'components/Slider/CardListSlider/CardListSlider.module.scss';
import SliderControl from 'components/Slider/SliderControl/SliderControl';
import Slider from 'components/Slider/Slider';
import classNames from 'classnames';
import React from 'react';

const CardListSlider = ({ name = 'cardlist-slider', children, settings }) => {
  const defaultSettings = {
    className: 'CardListSlider',
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: children.length > 4.2 ? 4.2 : 4,
    slidesToScroll: 1,
    prevArrow: (
      <SliderControl
        direction="left"
        customClassName={{
          arrow: styles.arrow,
          leftArrow: styles.leftArrow,
        }}
        buttonProps={{ size: 'small' }}
      />
    ),
    nextArrow: (
      <SliderControl
        direction="right"
        customClassName={{
          arrow: styles.arrow,
          rightArrow: styles.rightArrow,
        }}
        buttonProps={{ size: 'small' }}
      />
    ),
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 868,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
    ...settings,
  };

  return (
    <Slider
      name={name}
      {...defaultSettings}
      className={classNames(styles.slider, defaultSettings.className)}
    >
      {children}
    </Slider>
  );
};

CardListSlider.defaultProps = {
  settings: {},
};

export default CardListSlider;
