import styles from 'components/Slider/SliderControl/SliderControl.module.scss';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {
  Button,
  FontAwesome as FontAwesomeProIcon
 } from '@wienenergiegithub/ui-next/';

const SliderControl = ({
  direction,
  onClick,
  className,
  customClassName,
  buttonProps,
}) => {
  const disabled = className.includes('slick-disabled');

  return (
    <div
      data-purpose={`control-${direction}`}
      className={classNames(customClassName.arrow, {
        [customClassName.leftArrow]: direction === 'left',
        [customClassName.rightArrow]: direction === 'right',
      })}
    >
      <Button
        theme="secondary"
        shape="bubble"
        onClick={onClick}
        disabled={disabled}
        isElevated
        className={styles.arrowButton}
        {...buttonProps}
      >
        <FontAwesomeProIcon icon={`chevron-${direction}`} role="presentation" />
      </Button>
    </div>
  );
};

SliderControl.propTypes = {
  /* styles coming from the react-slick library */
  className: PropTypes.string,
  /* styles to position the arrow button */
  customClassName: PropTypes.shape({
    arrow: PropTypes.string,
    leftArrow: PropTypes.string,
    rightArrow: PropTypes.string,
  }),
  direction: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonProps: PropTypes.shape({ size: PropTypes.string }),
};

SliderControl.defaultProps = {
  className: '',
  customClassName: {},
  buttonProps: {},
  onClick: () => {},
};

export default SliderControl;
