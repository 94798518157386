import React from 'react';
import styles from './InfoCards.module.scss';

import CmsCardList from 'components/CmsComponents/components/CmsCardList/CmsCardList';

const InfoCards = () => {
  const cards = [
    {
      title: 'Informationsmaterialien',
      description: 'Alle Informationen zu GeoTief Wien finden Sie in unseren Informationsmaterialien.',
      icon: 'file-download',
      action: {
        title: 'GeoTief Wien Informationsfolder',
        url: '/ueber/informationsmaterial',
        target: 'blank',
      },
    },
    {
      title: 'Mitglied im Geothermieverein',
      description: 'Wien Energie ist Mitglied im Geothermieverein Österreich.',
      icon: 'hands-heart',
      action: {
        title: 'Website des Geothermievereins',
        url: 'https://www.geothermie-oesterreich.at',
        target: 'blank',
      },
    },
    {
      title: 'Sie haben Fragen?',
      description: 'Unsere ExpertInnen stehen Ihnen jederzeit zur Verfügung!',
      icon: 'question',
      action: {
        title: 'Ansprechpartner von GeoTief Wien',
        url: '/kontakt',
        target: '',
      },
    },
  ];

  return <CmsCardList type="helpCard" cards={cards} />;
};

export default InfoCards;
