global.dataLayer = global.dataLayer || [];

const addToDataLayer = shape => {
  global.dataLayer.push(shape);
};

const trackingHook = () => {
  return addToDataLayer;
};

export const trackInteraction = id => {
  const trimmed = id && id.trim();
  const validateInteractionId = value => {
    return RegExp('^(.+;.+;.+;.+)$').test(value);
  };
  if (!trimmed || !validateInteractionId(trimmed)) {
    return;
  }
  addToDataLayer({
    event: 'interactionInfo',
    interactionId: trimmed,
  });
};

export default trackingHook;
