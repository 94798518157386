import React from 'react';
import styles from './PartnerCardList.module.scss';
import { useStaticQuery, graphql } from 'gatsby';

import CmsCardList from 'components/CmsComponents/components/CmsCardList/CmsCardList';
import { Content, Heading } from '@wienenergiegithub/ui-next';

const PartnerCardList = ({ type }) => {
  // Defining query
  const data = useStaticQuery(graphql`
    query GetPartners {
      allPartnersYaml {
        edges {
          node {
            logo {
              publicURL
            }
            path
            title
          }
        }
      }
    }
  `);

  // Defining output variable
  const items = [];

  // Querying data
  data.allPartnersYaml.edges.forEach(element => {
    const partnerData = {
      title: element.node.title,
      icon: {
        src: element.node.logo != null ? element.node.logo.publicURL : '',
      },
      action: {
        title: element.node.title,
        url: `/projektpartner${element.node.path}`,
        target: '',
      },
    };
    items.push(partnerData);
  });

  // Sorting partners by name
  items.sort(function(a, b) {
    return a.title.localeCompare(b.title);
  });

  let output;
  if (type === 'slider') {
    output = (
      <>
        <Heading>Projektpartner</Heading>
        <Content style={{ marginBottom: 0 }}>
          GeoTief Wien ist ein Energie-Forschungsprojekt von Wien Energie
          gemeinsam mit AIT, Geologische Bundesanstalt, Geo5, Heinemann Oil
          (HOL), Montanuniversität Leoben OMV, RAG Austria AG, Universität Wien,
          Universität Salzburg und Zentralanstalt für Meteorologie.
        </Content>
        <CmsCardList title="" cards={items} type="iconSlidingCard" isSlider />
      </>
    );
  } else if (type === 'overview') {
    output = <CmsCardList title="" cards={items} type="iconSlidingCard" />;
  }

  return output;
};

export default PartnerCardList;
