import styles from 'components/CmsComponents/components/CmsCallToAction/CmsCallToAction.module.scss';
import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import {
  Button,
  FontAwesome as FontAwesomeProIcon,
} from '@wienenergiegithub/ui-next';

const CmsCallToAction = ({ items, className }) => (
  <div className={styles.container}>
    {items.map(
      ({ icon, link, theme, className: itemClassName, virtualq, ...props }) => (
        <Button
          key={link.title}
          shape="squared"
          as={Link}
          href={link.url}
          className={classNames(
            styles.button,
            styles[theme],
            className,
            itemClassName,
          )}
          isElevated
          {...virtualq}
          {...props}
        >
          <FontAwesomeProIcon
            icon={icon}
            className={styles.icon}
            role="presentation"
          />
          <span dangerouslySetInnerHTML={{ __html: link.title }} />
        </Button>
      ),
    )}
  </div>
);
export default CmsCallToAction;
