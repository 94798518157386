import CmsStage from 'components/CmsComponents/components/CmsStage/CmsStage';
import {
  Container,
} from '@wienenergiegithub/ui-next';

import styles from 'components/CmsComponents/components/CmsMainTeaser/CmsMainTeaser.module.scss';
import React from 'react';
import classNames from 'classnames';

const CmsMainTeaser = ({ items, background }) => (
  <div
    className={classNames(styles.container, {
      [styles.blueDouble]: background === 'blue' && items.length === 2,
      [styles.orangeDouble]: background === 'orange' && items.length === 2,
      [styles.orangeSingle]: background === 'orange' && items.length === 1,
      [styles.blueSingle]: background === 'blue' && items.length === 1,
      [styles.greenSingle]: background === 'green-left' && items.length === 1,
      [styles.greenDouble]: background === 'green-right',
      [styles.bottomGreen]: background === 'green-bottom',
      [styles.bottomOrange]:
        background === 'orange-bottom' && items.length === 1,
      [styles.bottomBlue]: background === 'blue-bottom' && items.length === 1,
    })}
  >
    <Container size="md">
      <div className={classNames(styles.cards)}>
        {items.map(({ heading, subheading, href, image }, index) => (
          <CmsStage
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={styles.card}
            variant="teaser"
            isReverse={index % 2 === 1}
            heading={heading}
            image={image}
            content={subheading}
            action={href}
          />
        ))}
      </div>
    </Container>
  </div>
);
CmsMainTeaser.size = 'full';

export { CmsMainTeaser as default };
