import React from 'react';
import styles from './IFrameWrapper.module.scss';

const IFrameWrapper = ({ url }) => {
  return (
    <div className={styles.frameContainer}>
      <iframe src={url} name="GeotiefExplore" scrolling="no" frameBorder="0" marginHeight="0" marginWidth="0" height="500" width="100%" allowFullScreen></iframe>
    </div>
  );
};

export { IFrameWrapper as default };
