import React from 'react';
import styles from 'components/Footer/Footer.module.scss';
import { Container } from '@wienenergiegithub/ui-next';

const Footer = () => {
  return (
    <footer id="page-footer" className={styles.container}>
      <Container className={styles.wrapper}>
        <div className={styles.copyrightContainer}>
          <p>
            <b>© {new Date().getFullYear()} Wien Energie GmbH</b>, Thomas-Klestil-Platz 14, 1030 Wien
          </p>
        </div>
        <nav className={styles.menuContainer} aria-label="Inhaltsangabe">
          <ul>
            <li>
              <a href="https://www.wienenergie.at/impressum/" target="_blank" rel="noreferrer">Impressum</a>
            </li>
            <li>
              <a href="https://www.wienenergie.at/datenschutz/" target="_blank" rel="noreferrer">Datenschutz</a>
            </li>
          </ul>
        </nav>
      </Container>
      <Container className={styles.closerWrapper}>
        <div className={styles.copyrightContainer}>
        <p>GeoTief Wien ist ein Energie-Forschungsprojekt von Wien Energie gemeinsam mit AIT, Geologische Bundesanstalt, Geo5, Heinemann Oil, Montanuniversität Leoben, OMV, RAG Austria, GFZ Potsdam, Universität Wien, Universität Salzburg und Zentralanstalt für Meteorologie. Das Projekt wurde zwischen 2016 und 2022 durchgeführt.</p>
        </div>
      </Container>
    </footer>
  );
};

export { Footer as default };