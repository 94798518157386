import React from 'react';
import List, { ListItem } from '@wienenergiegithub/ui-next/src/common/components/List/List';
import { 
  FontAwesome as FontAwesomeProIcon,
} from '@wienenergiegithub/ui-next';

const CmsList = ({ items, className, type, align }) => {
  let icon = null;

  switch (type) {
    case 'checkmark':
      icon = (
        <FontAwesomeProIcon type="regular" icon="check" role="presentation" />
      );
      break;
    case 'ul':
      icon = <FontAwesomeProIcon icon="circle" role="presentation" />;
      break;
    default:
      icon = null;
  }

  return (
    items.length > 0 && (
      <List type={type} icon={icon} align={align} className={className}>
        {items.map((item, index) => (
          <ListItem
            // eslint-disable-next-line react/no-array-index-key
            key={`listItem-${index}`}
          >
            {item}
          </ListItem>
        ))}
      </List>
    )
  );
};

CmsList.defaultProps = {
  items: [],
};
export default CmsList;
